import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import api from './api';
import SuccessModal from "./successModal";
import ErrorModal from "./errorModal";

function CreateOrder() {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState({
    customerName: '',
    customerAddress: '',
    customerContactNo: '',
    customerEmail: '',
    customerCity: '',
    customerPostalCode: '',
    description: '',
    status: 'Pending',
    totalFee: '',
    feesPaid: '',
    advanceFee: '',
    feesDue: 0,
    documentsUploaded: 'No',
    invoiceReference: '',
    paymentStatus: '',
    advanceFee: '',
    attachedDocuments: [],
    service: '',
    isAdmin: true,
    queryReference: ''
  });
  const [showSuccessModal, setSuccessModal] = useState(false)
  const [showErrorModal, setErrorModal] = useState(false)
  const [errors, setErrors] = useState({
    customerContactNo: '',
    customerEmail: ''
  });
  useEffect(() => {
    // If you need to fetch initial data, you can do it here
    // For now, we'll just set isAdmin to true
    setOrderData(prevState => ({
      ...prevState,
      isAdmin: true
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    // Convert numeric fields to integers
    if (['totalFee', 'feesPaid', 'feesDue', 'advanceFee'].includes(name)) {
      newValue = parseInt(value, 10) || 0;
    }

    setOrderData(prevState => {
      const updatedState = { ...prevState, [name]: newValue };

      // Calculate feesDue if totalFee or feesPaid changes
      if (name === 'totalFee' || name === 'feesPaid') {
        updatedState.feesDue = updatedState.totalFee - updatedState.feesPaid;
      }
      if (updatedState.advanceFee > updatedState.totalFee) {
        setErrors(prevErrors => ({
          ...prevErrors,
          advanceFee: 'Advance Fee cannot be greater than Total Fee.'
        }));
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          advanceFee: '' // Clear error if valid
        }));
      }

      return updatedState;
    });
  };



  const validateInputs = () => {
    const newErrors = {};
    const phoneRegex = /^(?:\+?[0-9]{1,3})?[0-9]{10,15}$/; // Adjust according to your needs
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!orderData.customerName) {
      newErrors.customerName = "Customer Name is required.";
    }
    if (!phoneRegex.test(orderData.customerContactNo)) {
      newErrors.customerContactNo = "Please enter a valid phone number.";
    }
    if (!emailRegex.test(orderData.customerEmail)) {
      newErrors.customerEmail = "Please enter a valid email address.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }
    if (orderData.advanceFee > orderData.totalFee) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        advanceFee: 'Advance Fee cannot be greater than Total Fee.',
      }));
      return; // Stop submission if the advance fee validation fails
    }
    try {
      const token = sessionStorage.getItem("token");
      const response = await api.post('/admin/create-order', orderData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.statusCode) {
        setSuccessModal(true)
      }
      else {
        setErrorModal(false)
      }

      // You may want to redirect the user or show a success message here
    } catch (error) {
      console.error("Error creating order:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <section className="ftco-section">
      <div className="container">
        <div className="creater-order table-1">
          <h2>Create Order</h2>
          <form onSubmit={handleSubmit}>
            <table className="order-table mb-4" cellPadding={1} cellSpacing={1}>
              <tbody>
                <tr>
                  <td>Order ID</td>
                  <td>{`<auto generated>`}</td>
                </tr>
                <tr>
                  <td>Query Reference</td>
                  <td><input type="text" name="queryReference" onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Customer Name</td>
                  <td>
                    <input type="text" name="customerName" value={orderData.customerName} onChange={handleChange} />
                    {errors.customerName && <div className="error-message text-danger">{errors.customerName}</div>}
                  </td>                </tr>
                <tr>
                  <td>Customer Address</td>
                  <td><input type="text" name="customerAddress" value={orderData.customerAddress} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Customer Contact Number</td>
                  <td>
                    <input
                      type="text"
                      name="customerContactNo"
                      value={orderData.customerContactNo}
                      onChange={(e) => {
                        const phoneNumber = e.target.value.replace(/[^\d+]/g, '');
                        handleChange({ target: { name: 'customerContactNo', value: phoneNumber } });
                      }}
                      pattern="^\+?[0-9]{1,15}$"
                      maxLength="15"
                      title="Please enter a valid international phone number"
                    />
                    {errors.customerContactNo && <div className="error-message text-danger">{errors.customerContactNo}</div>}
                  </td>
                </tr>
                <tr>
                  <td>Customer Email ID</td>
                  <td><input type="text" name="customerEmail" value={orderData.customerEmail} onChange={handleChange} />{errors.customerEmail && <div className="error-message text-danger">{errors.customerEmail}</div>}</td>
                </tr>
                <tr>
                  <td>Customer City</td>
                  <td><input type="text" name="customerCity" value={orderData.customerCity} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Customer Postal Code</td>
                  <td>
                    <input
                      type="text"
                      name="customerPostalCode"
                      value={orderData.customerPostalCode}
                      maxLength="6"
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, '').slice(0, 6);
                        handleChange({ target: { name: 'customerPostalCode', value: numericValue } });
                      }}
                    />
                  </td>
                </tr>


                <tr>
                  <td>Service</td>
                  <td><input type="text" name="service" value={orderData.service} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td><input type="text" name="description" value={orderData.description} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <select name="status" value={orderData.status} onChange={handleChange}>
                      <option value="Pending">Pending</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Complete">Complete</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Advance Fee</td>
                  <td><input type="number" name="advanceFee" value={orderData.advanceFee} onChange={handleChange} />{errors.advanceFee && <div className="error text-danger">{errors.advanceFee}</div>}</td>
                </tr>
                <tr>
                  <td>Total Fee</td>
                  <td><input type="number" name="totalFee" value={orderData.totalFee} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Fees Paid</td>
                  <td><input type="number" name="feesPaid" value={orderData.feesPaid} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Fees Due</td>
                  <td><input type="number" name="feesDue" value={orderData.feesDue} readOnly /></td>
                </tr>
                <tr>
                  <td>Documents Uploaded</td>
                  <td>
                    <select name="documentsUploaded" value={orderData.documentsUploaded} onChange={handleChange}>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Invoice Reference</td>
                  <td><input type="text" name="invoiceReference" value={orderData.invoiceReference} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Payment Status</td>
                  <td><input type="text" name="paymentStatus" value={orderData.paymentStatus} onChange={handleChange} /></td>
                </tr>
                <tr>
                  <td>Advance Fee</td>
                  <td><input type="number" name="advanceFee" value={orderData.advanceFee} onChange={handleChange} /></td>
                </tr>
              </tbody>
            </table>

            <section className="">
              <button type="submit" className="btn btn-success py-3 px-4 mr-4">Create Order</button>
              <Link to="/dashboard" className="btn btn-primary py-3 px-4">
                Go to Dashboard
              </Link>
            </section>
            {showSuccessModal && <SuccessModal showModal={showSuccessModal} message={"Order Created Successfully!"} isAdmin={true} />}
            {showErrorModal && <ErrorModal showModal={showErrorModal} />}
          </form>
        </div>
      </div>
    </section>
  );
}

export default CreateOrder;