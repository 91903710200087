import React from 'react'
import ProjectSection from './ProjectSection';
import Testimonials from './Testimonials';
import TAXFILINGImage from '../images/image_taxfiling.jpg';

function TaxFiling() {
  return (
    <>
      <section className="ftco-section" id="services-section">
        <div className="container">
          <h1>NRI Tax Filing- Claim TDS refund and Income Tax Returns</h1>
          <p>All Non-Resident Indians (NRIs), Persons of Indian Origin (PIOs), or Overseas Citizens of India (OCIs), are recommended to file an Income Tax Return (ITR) when they have taxable income in India. Although, as per the Income Tax Act, 1961, NRIs/PIOs/OCIs are mandated to file an ITR in India only if their total annual income in India exceeds:</p>
          <ul>
            <li>₹2.5 lakh* as per the existing tax regime or</li>
            <li>₹3 lakh** as per the new tax regime.</li>
          </ul>
          <p>Even in cases when your income does not exceed the mentioned income threshold in a given Financial Year (April-March), you are required to file an income tax return as an NRI if you have*:</p>
          <ul>
            <li>Deposited more than ₹50 lakh in a savings bank account in India.</li>
            <li>Deposited more than ₹1 crore in a current account with a bank or cooperative society in India.</li>
            <li>Tax Deducted at Source (TDS)/Tax Collected at Source (TCS) of more than ₹25,000.</li>
            <li>Incurred a travel-related expenditure from India** of more than ₹2 lakh for yourself or anyone else travelling to a foreign country.</li>
          </ul>
          <img src={TAXFILINGImage} alt="Tax Filing" />
          <h4 className="mt-4">How we can help?</h4>
          <ul>
            <li>NRE and NRO account TDS refunds, Tax deduction certificate for NRI</li>
            <li>Handling of Income tax notice or summons</li>
            <li>CA services and fees slab for filing ITR</li>
            <li>Process Income tax e-filing</li>
            <li>Notify on the tax returns.</li>
          </ul>
        </div>
      </section>
      <Testimonials />
    </>
  )
}

export default TaxFiling