import React, { useState } from "react";
import api from "./api"; // Import your API utility
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false); // New state to track if the API was successful
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .post("/auth/forgot-password", { email })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setMessage(
            "Password reset link has been sent. Please check your inbox."
          );
          setEmail("");
          setSuccess(true); 
          setTimeout(() => {
            navigate("/login");
          }, 10000); 
        } else {
          setMessage(response?.data?.message);
        }
      })
      .catch((error) => {
        console.error("Forgot password error:", error);
        setMessage("An error occurred. Please try again.");
      });
  };

  return (
    <section className="ftco-section w" id="services-section">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <form
              onSubmit={handleSubmit}
              className="bg-light p-4 p-md-5"
              style={{ minWidth: "600px", padding: "40px", borderRadius: "10px" }}
            >
              <h2 className="mb-3">Forgot Password</h2>
              {message && (
                <div className="alert alert-info" role="alert">
                  {message}
                </div>
              )}

              {/* Conditionally render the form fields if the request wasn't successful */}
              {!success && (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="email">Email Address:</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                          id="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="submit"
                          value="Reset Password"
                          className="btn btn-primary py-3 px-5 w-100"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Display login option even when success is true */}
              <div className="row">
                <div className="col-md-12">
                  <p className="text-center">
                    Remember your password? <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
