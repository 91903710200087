import React, { useState } from "react";
import OrderView from "./OrderView";
import AdminQuery from "./AdminQuery";

function AdminDashboard() {
  const [activeComponent, setActiveComponent] = useState("OrderView");

  const renderComponent = () => {
    switch (activeComponent) {
      case "OrderView":
        return <OrderView />;
      case "AdminQuery":
        return <AdminQuery />;
      default:
        return <OrderView />;
    }
  };

  return (
    <div className="d-flex">
      <div className="sidebar bg-light p-3 d-flex flex-column" style={{ width: '250px', height: '100vh' }}>
      <button
          className={`btn btn-outline-primary mb-2 ${activeComponent === "OrderView" ? "active" : ""}`}
          style={{ 
            backgroundColor: activeComponent === "OrderView" ? '#000043' : 'transparent',
            color: activeComponent === "OrderView" ? '#fff' : '#000043',
            borderColor: '#000043'
          }}
          onClick={() => setActiveComponent("OrderView")}
        >
          Order View
        </button>
        <button
          className={`btn btn-outline-primary ${activeComponent === "AdminQuery" ? "active" : ""}`}
          style={{ 
            backgroundColor: activeComponent === "AdminQuery" ? '#000043' : 'transparent',
            color: activeComponent === "AdminQuery" ? '#fff' : '#000043',
            borderColor: '#000043'
          }}
          onClick={() => setActiveComponent("AdminQuery")}
        >
          Query View
        </button>
      </div>
      <div className="main-content flex-grow-1 p-3">
        {renderComponent()}
      </div>
    </div>
  );
}

export default AdminDashboard;
