import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ErrorModal = ({showModal}) => {
  const [showError, setShowError] = useState(showModal);

  const handleCloseError = () => setShowError(false);



  return (
    <>
      {/* Error Modal */}
      <Modal show={showError} onHide={handleCloseError} centered>
        <Modal.Header>
          <Modal.Title>Error</Modal.Title>
          <Button variant="close" onClick={handleCloseError}>
            <i className="bi bi-x" style={{ fontSize: '1.5rem' }}></i> {/* Close icon */}
          </Button>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="mb-3">
            <i className="bi bi-x-circle-fill text-danger" style={{ fontSize: '3rem' }}></i>
          </div>
          <h5>An error occurred! Please try again.</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseError}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ErrorModal;
