import React from "react";
import ProjectSection from './ProjectSection';
import Testimonials from './Testimonials';
import PanCarImage from '../images/nriPan.jpg';

function PanCardServices() {
  return (
    <>
      <section className="ftco-section" id="services-section">
        <div className="container">
          <h1>PAN Card Services for NRI</h1>
          <p>
            An NRI PAN card is a Permanent Account Number issued by the Income
            Tax Department of India to Non-Resident Indians (NRIs) or Persons of
            Indian Origin (PIOs) who have income or transactions in India. It is
            a unique identification code mandatory for filing tax returns,
            opening bank accounts, investing in mutual funds, buying or selling
            property, and many other purposes such as:
          </p>
          <ul className="mb-5">
            <li>
              Having a PAN card is a prerequisite to investing in mutual funds.
            </li>
            <li>
              When opening an NRE or NRO account in India, which are specialised
              accounts for NRIs to manage Indian rupees or foreign currency, a
              PAN card is essential.
            </li>
            <li>
              A PAN card is mandatory for property transactions exceeding ₹10
              lakhs in India, including buying or selling.
            </li>
            <li>
              This document is also necessary if you have any taxable income in
              India, such as salary, rent, interest, dividends, or capital
              gains.
            </li>
            <li>
              The Income Tax Department utilises PAN cards to monitor your
              income and tax obligations.
            </li>
          </ul>
          <img src={PanCarImage} alt="Pan Card Services for NRI" />
          <h4 className="mt-4">GUARANTEED ON TIME DELIVERY</h4>
          <p>
            <strong>How we can help?</strong>
          </p>
          <p>
            We can help in obtaining new PAN Card, Duplicate or Reissue of lost
            PAN card, Correct, Rectify, Modify, Update, Reprint, Change of name,
            address, photo, email, etc. PAN card for Foreign passport holders
            with or without OCI or PIO and PAN for Company. Our registered
            chartered accountants team will co-ordinate with Income tax
            department, TIN NSDL, UTI on behalf of you.
          </p>
          <p>
            <strong>Eligibility Criteria?</strong>
          </p>
          <p>
            Individuals with Indian passport, foreign passports, NRI, OCI, PIO,
            minors, HUF, foreign companies, Charitable Trusts, NGO,
            Associations. Card will be delivered in India or Overseas wherever
            you are.
          </p>
        </div>
      </section>
      <Testimonials />
    </>
  );
}

export default PanCardServices;
