import React from 'react'
import ProjectSection from './ProjectSection';
import Testimonials from './Testimonials';
import NRICASHGIFTImage from '../images/image_nriCash.jpg';

function CashGifts() {
  return (
    <>
      <section className="ftco-section" id="services-section">
        <div className="container">
          <h1>NRI Cash Gifts</h1>
          <p>Gifts in India are taxable under the Income Tax Act. Gift can be in cash or kind (moveable or immoveable property and other assets specified under the Act). A resident Indian can make a gift to an NRI through a gift deed, but there is a claim on gifts received by NRI. The permissibility for receipt of gift is regulated by the Foreign Exchange Management Act, 1999 (FEMA), and its taxability under the Income Tax Act, 1961 (IT Act).</p>
          <p>As per section (3) of the Gift Tax Act, 1958, gift tax was abolished in India in 1998. You will not be taxed on the gifts received from relatives. Gifts received (from relatives or non-relatives) on the occasion of marriage, under a Will, or in contemplation of death of the donor are tax-free.</p>
          <p>Resident Indians can gift in Indian rupees in India or gift via liberalised remittance scheme in foreign currency upto $250000 a year. NRIs receiving in foreign currency shall be taxed as per the income tax rules in their country of residence. Under the LRS scheme, you are liable to pay a 5% TCS (Tax Collected at Source) for remittances exceeding the limit of ₹ 7,00,000. However, you can claim a refund for the deducted TCS while filing ITR (income tax return) using Form 26AS.</p>
          <p>If the money received (in aggregate) is in excess of ₹ 50,000 then, the entire gift amount is taxable.</p>
          <img src={NRICASHGIFTImage} alt="NRI Cash Gifts" />
          <h4 className="mt-4">How we can help?</h4>
          <ul>
            <li>Establishing the source of gift</li>
            <li>Supply FORM CA Certificate Form 15CA, 15CB (we will provide)</li>
            <li>to move funds to NRE</li>
          </ul>
        </div>
      </section>
      <Testimonials />
    </>
  )
}

export default CashGifts