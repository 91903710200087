import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../images/sitra-logo.png';
import flag from '../images/sitra-flag.jpg';

const Navbar = ({ accountDetails, onLogout }) => {
  const [isSolutionsDropdownOpen, setIsSolutionsDropdownOpen] = useState(false);
  const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleSolutionsDropdown = (e) => {
    e.preventDefault();
    setIsSolutionsDropdownOpen(!isSolutionsDropdownOpen);
  };

  const toggleLoginDropdown = (e) => {
    e.preventDefault();
    setIsLoginDropdownOpen(!isLoginDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const closeDropdown = () => {
    setIsSolutionsDropdownOpen(false);
    setIsLoginDropdownOpen(false);
  };

  const handleLogout = () => {
    onLogout();
    closeMobileMenu(); // Close menu on logout
  };

  return (
    <header>
      <nav className="navbar navbar-expand-md">
        <div className='container'>
          <Link className="navbar-brand" to="/" onClick={closeMobileMenu}>
            <img src={logo} alt="Sitra Solutions" width={211} height={69} />
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleMobileMenu}>
            <span className="oi oi-menu"></span> 
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#ffffff" className="bi bi-list" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
            </svg>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink exact to="/" className="nav-link" activeClassName="active" onClick={closeMobileMenu}>
                  <span>Home</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about-us" className="nav-link" activeClassName="active" onClick={closeMobileMenu}>
                  <span>About Us</span>
                </NavLink>
              </li>
              <li
                className={`nav-item dropdown ${isSolutionsDropdownOpen ? 'show' : ''}`}
                onMouseEnter={() => setIsSolutionsDropdownOpen(true)}
                onMouseLeave={() => setIsSolutionsDropdownOpen(false)}
              >
                <a
                  href="/pan-card-services"
                  className="dropdown-toggle nav-link align-items-center d-none d-md-block"
                  role="button"
                  aria-expanded={isSolutionsDropdownOpen}
                  onClick={toggleSolutionsDropdown}
                >
                  Solutions
                </a>
                <a
                  href="#"
                  className="dropdown-toggle nav-link align-items-center d-block d-md-none"
                  role="button"
                  aria-expanded={isSolutionsDropdownOpen}
                  onClick={toggleSolutionsDropdown}
                >
                  Solutions
                </a>
                <div className={`dropdown-menu ${isSolutionsDropdownOpen ? 'show' : ''}`} onClick={closeDropdown}>
                  <NavLink className="dropdown-item" to="/pan-card-services" onClick={closeMobileMenu}>PAN Card Services</NavLink>
                  <NavLink className="dropdown-item" to="/digital-signature-certificate" onClick={closeMobileMenu}>Digital Signature Certificate</NavLink>
                  <NavLink className="dropdown-item" to="/tax-filing" onClick={closeMobileMenu}>Tax Filing</NavLink>
                  <NavLink className="dropdown-item" to="/cash-gifts" onClick={closeMobileMenu}>Cash Gifts</NavLink>
                  <NavLink className="dropdown-item" to="/capital-gains" onClick={closeMobileMenu}>Capital Gains</NavLink>
                  <NavLink className="dropdown-item" to="/funds-saving-account" onClick={closeMobileMenu}>Funds in Resident Savings Account</NavLink>
                </div>
              </li>
              <li className="nav-item">
                <NavLink to="/contact-us" className="nav-link" activeClassName="active" onClick={closeMobileMenu}>
                  <span>Contact Us</span>
                </NavLink>
              </li>
              {accountDetails?.user?.firstName ? (
                <>
                  <li
                    className={`nav-item dropdown ${isLoginDropdownOpen ? 'show' : ''}`}
                    onMouseEnter={() => setIsLoginDropdownOpen(true)}
                    onMouseLeave={() => setIsLoginDropdownOpen(false)}
                  >
                    <a
                      href="#"
                      className="dropdown-toggle nav-link d-flex align-items-center"
                      role="button"
                      aria-expanded={isLoginDropdownOpen}
                      onClick={toggleLoginDropdown}
                    >
                      Hi, {accountDetails.user.firstName}
                    </a>
                    <div className={`dropdown-menu ${isLoginDropdownOpen ? 'show' : ''}`} onClick={closeDropdown}>
                      <NavLink className="dropdown-item" to="/dashboard" onClick={closeMobileMenu}>Dashboard</NavLink>
                      <button className="dropdown-item" onClick={handleLogout}>Logout</button>
                    </div>
                  </li>
                </>
              ) : (
                <li
                  className={`nav-item dropdown ${isLoginDropdownOpen ? 'show' : ''}`}
                  onMouseEnter={() => setIsLoginDropdownOpen(true)}
                  onMouseLeave={() => setIsLoginDropdownOpen(false)}
                >
                  <a
                    href="#"
                    className="dropdown-toggle nav-link d-flex align-items-center"
                    role="button"
                    aria-expanded={isLoginDropdownOpen}
                    onClick={toggleLoginDropdown}
                  >
                    Login / Register
                  </a>
                  <div className={`dropdown-menu ${isLoginDropdownOpen ? 'show' : ''}`} onClick={closeDropdown}>
                    <NavLink className="dropdown-item" to="/login" onClick={closeMobileMenu}>Login</NavLink>
                    <NavLink className="dropdown-item" to="/register" onClick={closeMobileMenu}>Register</NavLink>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
